"use strict";
exports.__esModule = true;
exports.timezones = void 0;
exports.timezones = [
    // START DAYLIGHT SAVINGS
    {
        name: 'PST',
        description: '(PST) Pacific Standard Time',
        offset: '-07:00',
        isDstObserved: true
    },
    {
        name: 'MST',
        description: '(MST) Mountain Standard Time',
        offset: '-06:00',
        isDstObserved: true
    },
    {
        name: 'EST',
        description: '(EST) Eastern Standard Time',
        offset: '-04:00',
        isDstObserved: true
    },
    {
        name: 'CST',
        description: '(CST) Central Standard Time',
        offset: '-06:00',
        isDstObserved: true
    },
    {
        name: 'HST',
        description: '(HST) Hawaii Standard Time',
        offset: '-9:00',
        isDstObserved: true
    },
    {
        name: 'AST',
        description: '(AST) Alaska Standard Time',
        offset: '-08:00',
        isDstObserved: true
    },
    // END DAYLIGHT SAVINGS
    {
        name: 'GMT',
        description: '(GMT) Greenwich Mean Time',
        offset: '+0:00',
        isDstObserved: false
    },
    {
        name: 'ECT',
        description: '(ECT) European Central Time',
        offset: '+01:00',
        isDstObserved: false
    },
    {
        name: 'EET',
        description: '(EET) Eastern European Time',
        offset: '+02:00',
        isDstObserved: false
    },
    {
        name: 'ART',
        description: '(ART) Egypt Standard Time',
        offset: '+02:00'
    },
    {
        name: 'EAT',
        description: '(EAT) Eastern African Time',
        offset: '+03:00',
        isDstObserved: false
    },
    {
        name: 'MET',
        description: '(MET) Middle East Time',
        offset: '+03:30',
        isDstObserved: false
    },
    {
        name: 'NET',
        description: '(NET) Near East Time',
        offset: '+04:00',
        isDstObserved: false
    },
    {
        name: 'PLT',
        description: '(PLT) Pakistan Lahore Time',
        offset: '+05:00',
        isDstObserved: false
    },
    {
        name: 'IST',
        description: '(IST) India Standard Time',
        offset: '+05:30',
        isDstObserved: false
    },
    {
        name: 'BST',
        description: '(BST) Bangladesh Standard Time',
        offset: '+06:00',
        isDstObserved: false
    },
    {
        name: 'VST',
        description: '(VST) Vietnam Standard Time',
        offset: '+07:00',
        isDstObserved: false
    },
    {
        name: 'CTT',
        description: '(CTT) China Taiwan Time',
        offset: '+08:00',
        isDstObserved: false
    },
    {
        name: 'JST',
        description: '(JST) Japan Standard Time',
        offset: '+09:00',
        isDstObserved: false
    },
    {
        name: 'ACT',
        description: '(ACT) Australia Central Time',
        offset: '+09:30',
        isDstObserved: false
    },
    {
        name: 'AET',
        description: '(AET) Australia Eastern Time',
        offset: '+10:00',
        isDstObserved: false
    },
    {
        name: 'SST',
        description: '(SST) Solomon Standard Time',
        offset: '+11:00',
        isDstObserved: false
    },
    {
        name: 'NST',
        description: '(NST) New Zealand Standard Time',
        offset: '+12:00',
        isDstObserved: false
    },
    {
        name: 'MIT',
        description: '(MIT) Midway Islands Time',
        offset: '-11:00',
        isDstObserved: false
    },
    {
        name: 'IET',
        description: '(IET) Indiana Eastern Standard Time',
        offset: '-05:00',
        isDstObserved: false
    },
    {
        name: 'PRT',
        description: '(PRT) Puerto Rico and US Virgin Islands Time',
        offset: '-04:00',
        isDstObserved: false
    },
    {
        name: 'CNT',
        description: '(CNT) Canada Newfoundland Time',
        offset: '-03:30',
        isDstObserved: false
    },
    {
        name: 'AGT',
        description: '(AGT) Argentina Standard Time',
        offset: '-03:00',
        isDstObserved: false
    },
    {
        name: 'BET',
        description: '(BET) Brazil Eastern Time',
        offset: '-03:00',
        isDstObserved: false
    },
    {
        name: 'CAT',
        description: '(CAT) Central African Time',
        offset: '-01:00',
        isDstObserved: false
    },
];
