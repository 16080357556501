"use strict";
exports.__esModule = true;
exports.API_PROJECTS_ROUTE = exports.USER_DASHBOARD_SETTINGS_PROFILE = exports.EVENT_VERIFIER_ROOT = exports.USER_DASHBOARD_PASSES = exports.USER_DASHBOARD_EVENTS = void 0;
// User dashboard
exports.USER_DASHBOARD_EVENTS = '/api/dashboard/events';
exports.USER_DASHBOARD_PASSES = '/api/dashboard/passes';
// Event Verifier
exports.EVENT_VERIFIER_ROOT = '/api/user/verify';
// Settings
exports.USER_DASHBOARD_SETTINGS_PROFILE = '/api/dashboard/settings/profile';
// Project
exports.API_PROJECTS_ROUTE = '/api/dashboard/projects';
