import { definitions } from '@ethpass/types/supabase';
import { keccak256, toUtf8Bytes } from 'ethers/lib/utils.js';
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

export default function Avatar(props: { user: any }) {
  if (!props.user) return null;
  return props.user?.image ? (
    <img className="h-8 w-8 rounded-full border border-gray-400" src={props.user.image} alt="" />
  ) : (
    <Jazzicon
      diameter={30}
      paperStyles={{ border: '1px solid lightgray' }}
      seed={jsNumberForAddress(keccak256(toUtf8Bytes(props?.user?.email)) as string)}
    />
  );
}
