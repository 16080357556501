import { CheckIcon, ClipboardCopyIcon } from '@heroicons/react/outline';
import { useState } from 'react';

export default function CopyToClipboard({ text }) {
  const [copied, setCopied] = useState<boolean>(false);

  const onCopy = () => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <button type="button" onClick={onCopy} className="ml-2">
      {copied ? (
        <div className="mx-auto flex items-center justify-center h-5 w-5 rounded-full bg-green-100">
          <CheckIcon className="h-4 w-4 text-green-600" aria-hidden="true" />
        </div>
      ) : (
        <ClipboardCopyIcon className="-ml-0.5 mr-2 h-5 w-5" aria-hidden="true" />
      )}
    </button>
  );
}
