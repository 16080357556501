import polygonImage from '../../public/assets/network-logos/polygon.svg';
import ethereumImage from '../../public/assets/network-logos/ethereum.svg';
import arbitrumImage from '../../public/assets/network-logos/arbitrum.svg';
import optimismImage from '../../public/assets/network-logos/optimism.svg';
import bscImage from '../../public/assets/network-logos/bsc.svg';
import solanaImage from '../../public/assets/network-logos/solana.svg';
import gnosisImage from '../../public/assets/network-logos/gnosis.svg';
import baseImage from '../../public/assets/network-logos/base.svg';
import flowImage from '../../public/assets/network-logos/flow.svg';
import hederaImage from '../../public/assets/network-logos/hedera.svg';
import { ChainId, FlowNetwork, SolanaNetwork } from '@ethpass/types/contract';
import { Chains } from '@ethpass/types/pass';

export const evmNetworkLogos = {
  [ChainId.MAINNET]: ethereumImage,
  [ChainId.SEPOLIA]: ethereumImage,
  [ChainId.MATIC]: polygonImage,
  [ChainId.AMOY]: polygonImage,
  [ChainId.OPTIMISM]: optimismImage,
  [ChainId.OPTIMISM_SEPOLIA]: optimismImage,
  [ChainId.ARBITRUM]: arbitrumImage,
  [ChainId.ARBITRUM_SEPOLIA]: arbitrumImage,
  [ChainId.BINANCE_MAINNET]: bscImage,
  [ChainId.BINANCE_TESTNET]: bscImage,
  [ChainId.GNOSIS]: gnosisImage,
  [ChainId.BASE]: baseImage,
  [ChainId.BASE_SEPOLIA]: baseImage,
  [ChainId.HEDERA]: hederaImage,
  [ChainId.HEDERA_TESTNET]: hederaImage,
};

export const solanaNetworkLogos = {
  [SolanaNetwork.MAINNET]: solanaImage,
  [SolanaNetwork.DEVNET]: solanaImage,
  [SolanaNetwork.TESTNET]: solanaImage,
};

export const flowNetworkLogos = {
  [FlowNetwork.MAINNET]: flowImage,
  [FlowNetwork.TESTNET]: flowImage,
};

export const networkLogos = (chain, network) => {
  if (chain === Chains.EVM) {
    return evmNetworkLogos[network];
  } else if (chain === Chains.SOLANA) {
    return solanaNetworkLogos[network];
  } else if (chain === Chains.FLOW) {
    return flowNetworkLogos[network];
  }
};
